import { useEffect, useState } from "react";

import { Formik, Form, Field } from "formik";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";

import { TextFormField, Button, Spinner, Error } from "../../../components";
import { VALIDATION_MESSAGES, TEXTS, MAIN_SITE_URL } from "../../../constants";
import { PAGES } from "../../../constants/pages";
import {
  setTeam,
  setProfile,
  useUserDispatch,
  usePermissionsState,
} from "../../../context";
import { removeToken, setToken } from "../../../utils";
import {
  useLoginQuery,
  useProfileDataQuery,
  useMyTeamsQuery,
} from "../queries";
import { ITeamResponse } from "types/Team";
import { IProfileResponse } from "types/Profile";
import { ILoginResponse } from "types/Auth";

const validationSchema = yup.object({
  email: yup
    .string()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Email"))
    .email(VALIDATION_MESSAGES.FORMAT_IS_WRONG("Email")),
  password: yup
    .string()
    .min(8, VALIDATION_MESSAGES.MIN_LENGTH("Password", 8))
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Password")),
});

const LoginForm = () => {
  const history = useHistory();
  const dispatch = useUserDispatch();
  const { status: userStatus } = usePermissionsState();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { data: businessProfilesData, getProfileData } = useProfileDataQuery({
    onSuccess: (data: IProfileResponse) => {
      dispatch(setProfile(data?.profiles?.[0]));
    },
  });

  const {
    getMyTeams,
    data: teamsData,
    isSuccess: isTeamsDataSuccess,
  } = useMyTeamsQuery({
    onSuccess: (data: ITeamResponse) => {
      const team = data?.teams?.[0];
      dispatch(setTeam(team));
    },
  });

  const {
    status,
    error,
    mutate: login,
  } = useLoginQuery({
    onSuccess: async (data: ILoginResponse) => {
      setToken(data?.access, data?.refresh);
      await getProfileData();
      await getMyTeams();
      setShouldRedirect(true);
    },
  });

  useEffect(() => {
    if (!isTeamsDataSuccess || !shouldRedirect || !userStatus) return;

    const teamsLength = teamsData?.teams?.length;
    const team = teamsData?.teams?.[0];
    if (!Boolean(teamsLength)) {
      removeToken();
      history.replace(PAGES.LOGOUT, { notOwner: true });
    } else {
      history.push(PAGES.MANAGE_USERS);
    }

    setShouldRedirect(false);
  }, [
    history,
    isTeamsDataSuccess,
    teamsData,
    businessProfilesData,
    shouldRedirect,
    userStatus,
  ]);

  const isLoading = status === "loading";

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ email: "", password: "" }}
      onSubmit={values => {
        history.replace("/auth/login", { notOwner: false });
        login({ authData: values });
      }}
      enableReinitialize
    >
      {({ isValid }) => (
        <Form>
          <div className="mb-8">
            <Field label="Email" name="email" component={TextFormField} />
          </div>
          <div className="mb-3">
            <Field
              label="Password"
              name="password"
              type="password"
              component={TextFormField}
            />
          </div>
          <div className="mb-8">
            <>
              <Link
                className="text-base hover:opacity-75 transition-all duration-150"
                to="/auth/forgot-password"
              >
                {TEXTS.signInPage.FORGOT_PASSWORD}
              </Link>
              {error && <Error className="" {...{ error }} />}
            </>
          </div>
          <div>
            <Button
              variant="black"
              disabled={!isValid || isLoading}
              block
              uppercase
              data-testid="sign-in-button"
            >
              {isLoading ? <Spinner color="white" /> : TEXTS.signInPage.BUTTON}
            </Button>
          </div>
          <div className="mt-12 text-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={MAIN_SITE_URL}
              className="text-custom-gray-400 text-base"
            >
              {TEXTS.signInPage.DONT_HAVE_OVOU_CARD}
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
