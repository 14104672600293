import { FC } from "react";
import WalkthroughModal from "../../components/WalkThrough";
import { usePermissionsState } from "context/Permissions";
import { useFlag } from "../../utils";
import { FEATURE_FLAGS } from "constants/flags";
import { useUserState } from "context";
import { UPGRADE_PAGES } from "constants/pages";
import { USER_IAM_ROLE } from "types/TeamUsersResponse";

interface UpgradeModalProps {
  showUpgradeModal: boolean;
  onClose: () => void;
}

const UpgradeModal: FC<UpgradeModalProps> = ({ showUpgradeModal, onClose }) => {
  const isNewUpgrade = useFlag(FEATURE_FLAGS.NEW_UPGRADE);
  const { currentTeam } = useUserState();
  const { permission } = usePermissionsState();

  const walkthroughId = permission?.user_iam_role || "";

  if (([USER_IAM_ROLE.TEAM_ACTIVE_OWNER, USER_IAM_ROLE.TEAM_ACTIVE_ADMIN] as Array<USER_IAM_ROLE | undefined>).includes(permission?.user_iam_role)) {
    return null;
  }

  return (
    <WalkthroughModal
      isWalkthroughOpen={showUpgradeModal}
      closeWalkthrough={onClose}
      walkthroughId={walkthroughId}
      defaultLink={
        isNewUpgrade
          ? ""
          : `/${UPGRADE_PAGES.SELECT_YOUR_PLAN}?teamId=${currentTeam?.id}&currency=USD&interval=year`
      }
    />
  );
};

export default UpgradeModal;
