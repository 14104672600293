import { FC, ReactElement, useEffect } from "react";

import { TopBar, PageBody, Helmet, Box } from "components";
import { useCheckPermission, useIsActionAllowed, useRouter } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { useUpgradeModal } from "hooks/useUpgradeModal";
import { usePermissionsState } from "context/Permissions";

interface IPageSetup {
  title: string;
  feature: FEATURES;
  titleElement?: ReactElement;
  subTitle?: string;
  showBackArrow?: boolean;
  backUrl?: string;
  backArrowText?: string;
  centerElement?: ReactElement;
  rightElement?: ReactElement;
  rightBodyElement?: ReactElement;
  visibleInMobile?: boolean;
  children: ReactElement;
}

const PageSetup: FC<IPageSetup> = ({
  title = "",
  titleElement,
  subTitle = "",
  showBackArrow = false,
  backUrl,
  backArrowText,
  centerElement,
  rightElement,
  rightBodyElement,
  visibleInMobile,
  feature,
  children,
}) => {
  const { push, back } = useRouter();
  const { handleAction } = useIsActionAllowed();
  const isPageVisible = useCheckPermission(
    FEATURE_ACTION.VIEW,
    feature as FEATURES,
  );
  const { openModal } = useUpgradeModal();
  const { permission } = usePermissionsState();

  useEffect(() => {
    // Only show upgrade modal if permissions are loaded and the page is not visible
    if (permission && !isPageVisible) {
      handleAction(isPageVisible, feature, undefined, openModal);
    }
  }, [isPageVisible, feature, permission]);

  return (
    <Box>
      <Helmet title={`OVOU | ${title}`} />
      <TopBar
        showBackArrow={showBackArrow}
        backArrowText={backArrowText}
        centerElement={centerElement}
        rightElement={rightElement}
        handleBackButton={() => (backUrl ? push(backUrl) : back())}
      />
      <PageBody
        title={title}
        titleElement={titleElement}
        subTitle={subTitle}
        rightBodyElement={rightBodyElement}
        visibleInMobile={visibleInMobile}
        blurContent={!isPageVisible}
      >
        {children}
      </PageBody>
    </Box>
  );
};

export default PageSetup;
