import { FC, useCallback } from "react";
import { Button, ProfileEmbedded } from "components";

import { Link } from "react-router-dom";
import { IProfileActionCol } from "./types";
import { useCheckPermission, useIsActionAllowed, useTracking } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { useDisclosure } from "@chakra-ui/react";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { useUpgradeModal } from "hooks/useUpgradeModal";
import { isProfileComplete } from "utils/userStatus";

const ProfileActionCol: FC<IProfileActionCol> = ({
  row,
  teamId,
  refetchUsers,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const track = useTracking();
  const canSetupProfile = useCheckPermission(
    FEATURE_ACTION.EDIT,
    FEATURES.MANAGE_USERS_SETUP_PROFILE,
  );
  const canEditProfile = useCheckPermission(
    FEATURE_ACTION.EDIT,
    FEATURES.MANAGE_USERS_EDIT_PROFILE,
  );
  const { openModal } = useUpgradeModal();
  const { handleAction } = useIsActionAllowed();
  const { profile } = row;
  const isComplete = isProfileComplete(
    profile?.wizard_step,
    profile?.admin_wizard_step,
  );

  const handleSetupProfile = useCallback(() => {
    handleAction(
      canSetupProfile,
      FEATURES.MANAGE_USERS_SETUP_PROFILE,
      () => {
        onOpen();
        track({
          eventName: ANALYTICS_EVENTS.MANAGE_USERS_SETUP_PROFILE,
        });
      },
      openModal,
    );
  }, [canSetupProfile, openModal, track]);

  const handleEditProfile = useCallback(() => {
    handleAction(
      canEditProfile,
      FEATURES.MANAGE_USERS_EDIT_PROFILE,
      () => {
        onOpen();
        track({
          eventName: ANALYTICS_EVENTS.MANAGE_USERS_EDIT_PROFILE,
        });
      },
      openModal,
    );
  }, [canEditProfile, openModal, track]);

  const handleCloseEmbedded = () => {
    onClose();
    refetchUsers();
  };

  return (
    <>
      <div className="flex justify-center items-center w-full">
        {isComplete ? (
          <Link to={"#"} className="w-full" onClick={handleEditProfile}>
            <Button size="small" variant="white" block>
              Edit Profile
            </Button>
          </Link>
        ) : (
          <Button
            onClick={handleSetupProfile}
            size="small"
            variant="black"
            block
          >
            Setup Profile
          </Button>
        )}
      </div>
      <ProfileEmbedded
        isOpen={isOpen}
        onClose={handleCloseEmbedded}
        profileId={String(row?.profile?.id)}
        teamId={String(teamId)}
        roleId={row?.user_role.id}
      />
    </>
  );
};

export default ProfileActionCol;
