import { FC, useCallback, useEffect, useState } from "react";

import { useQueryCache } from "react-query";

import {
  Box,
  Button,
  PageSetup,
  SeatsCounter,
  ShimmerPlaceholder,
} from "../../components";
import { TEXTS } from "../../constants";
import { setTeam, useUserDispatch, useUserState } from "../../context";
import { AssignSeatsModal } from "../../modals";
import { useUserInvite } from "hooks/useUserInvite";
import { OnboardingModal } from "features/onboarding";
import { useToasts } from "react-toast-notifications";
import { useCheckPermission, useIsActionAllowed, useTracking } from "hooks";
import ManageUsersTable from "./ManageUsersTable";
import { useTeamUsersQuery } from "./api/useTeamUsersQuery";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { useMyTeamsQuery } from "features/auth/queries/useMyTeamsQuery";
import { ITeamResponse } from "types/Team";
import { useCompleteWizardStep } from "./api/useCompleteWizardStep";
import { FEATURE_FLAGS } from "constants/flags";
import { useFlag } from "utils";
import { useUpgradeModal } from "hooks/useUpgradeModal";
import { isTeamWizardStepComplete } from "utils/userStatus";
const ManageUsers: FC = () => {
  const track = useTracking();
  const queryCache = useQueryCache();

  const canViewInviteUserModal = useCheckPermission(
    FEATURE_ACTION.VIEW,
    FEATURES.INVITE_USERS_MODAL,
  );

  const { handleAction } = useIsActionAllowed();

  const { currentTeam } = useUserState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useUserDispatch();
  const isTeamOnboardingVideoEnabled = useFlag(
    FEATURE_FLAGS.TEAM_ONBOARDING_VIDEO_MODAL,
  );

  const { openModal } = useUpgradeModal();

  const teamId = currentTeam?.id as number;
  const {
    num_seats: numSeats = 0,
    pending_seats: pendingSeats = 0,
    assigned_seats: assignedSeats = 0,
  } = currentTeam || {};

  const { mutate: fireCompleteWizardStep } = useCompleteWizardStep();

  const { data: teamsData, getMyTeams } = useMyTeamsQuery({
    onSuccess: (data: ITeamResponse) => {
      const team = data?.teams?.[0];
      dispatch(setTeam(team));
    },
  });
  const { data: teamUsers, refetch: refetchUsers } = useTeamUsersQuery({
    teamId,
  });
  const { mutate: fireSendUserInvite, isLoading: userInviteLoading } =
    useUserInvite();

  const openInviteUserModal = useCallback(() => {
    handleAction(
      canViewInviteUserModal,
      FEATURES.INVITE_USERS_MODAL,
      () => {
        setIsModalOpen(true);
        track({
          eventName: ANALYTICS_EVENTS.MANAGE_USERS_OPEN_INVITE_USERS_MODAL,
        });
      },
      openModal,
    );
  }, [canViewInviteUserModal]);

  const handleInviteUsers = (emails: string[]) => {
    fireSendUserInvite(
      { teamId, emails },
      {
        onSuccess: async ({ message }) => {
          addToast(message, {
            appearance: "success",
          });
          queryCache.invalidateQueries([`team-users`, { teamId }]);
          await Promise.all([refetchUsers(), getMyTeams()]);

          setIsModalOpen(false);
          track({
            eventName: ANALYTICS_EVENTS.MANAGE_USERS_INVITE_USERS,
            customTrackingProps: {
              emails,
            },
          });
        },
        onError: (error: any) => {
          addToast(error, {
            appearance: "error",
          });
        },
      },
    );
  };

  useEffect(() => {
    const wizard_step = teamsData?.teams?.[0]?.wizard_step;
    if (
      isTeamOnboardingVideoEnabled &&
      wizard_step &&
      !isTeamWizardStepComplete(wizard_step)
    ) {
      setIsOnboardingModalOpen(true);
    }
  }, [isTeamOnboardingVideoEnabled, teamsData]);

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const onboardingOnClose = () => {
    setIsOnboardingModalOpen(false);
    const team_id = teamsData?.teams?.[0]?.id;
    if (team_id) {
      fireCompleteWizardStep({ teamId: team_id });
    }
  };

  return (
    <PageSetup
      title={TEXTS.routes.MANAGE_USERS}
      titleElement={
        <Box marginLeft="20px">
          {currentTeam == undefined ? (
            <ShimmerPlaceholder />
          ) : (
            <SeatsCounter
              assignedSeats={assignedSeats}
              pendingSeats={pendingSeats}
              numberOfSeats={numSeats}
            />
          )}
        </Box>
      }
      subTitle={TEXTS.routes.MANAGE_USERS_SUB_TITLE}
      rightBodyElement={
        <Button
          className="ml-auto"
          size="small"
          variant="primary"
          block
          onClick={() => openInviteUserModal()}
        >
          {TEXTS.common.INVITE_USERS}
        </Button>
      }
      feature={FEATURES.MANAGE_USERS}
    >
      <>
        <ManageUsersTable
          teamId={teamId}
          openAssignSeatsModal={() => openInviteUserModal()}
        />
        {canViewInviteUserModal && (
          <AssignSeatsModal
            teamUsers={teamUsers?.results!}
            isOpen={isModalOpen}
            isLoading={userInviteLoading}
            onClose={() => {
              setIsModalOpen(false);
            }}
            handleInviteUsers={handleInviteUsers}
          />
        )}

        <OnboardingModal
          isOpen={isOnboardingModalOpen}
          onClose={onboardingOnClose}
        />
      </>
    </PageSetup>
  );
};

export default ManageUsers;
