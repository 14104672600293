import { PROFILE_WIZARD_STEP } from "types/Profile";
import { TEAM_WIZARD_STEP } from "types/Team";

export const isTeamWizardStepComplete = (status: TEAM_WIZARD_STEP): boolean =>
  status === TEAM_WIZARD_STEP.COMPLETE;

export const isProfileComplete = (
  wizardStep?: PROFILE_WIZARD_STEP,
  adminWizardStep?: PROFILE_WIZARD_STEP,
): boolean =>
  wizardStep === PROFILE_WIZARD_STEP.COMPLETE ||
  adminWizardStep === PROFILE_WIZARD_STEP.COMPLETE;
