import { MAIN_SITE, BUSINESS_SITE_URL } from "constants/index";

const MY_PROFILE = "/my-profile";
export const PAGES = {
  MANAGE_USERS: "/manage-users",
  UPGRADE: "/team-upgrade",
  PROFILE_SETUP: `${MAIN_SITE}/onboarding`,
  RESET_PASSWORD: "/auth/reset-password",
  SIGN_IN: `${MAIN_SITE}/login`,
  LOGOUT: "/auth/logout",
  MY_PROFILE,
  EDIT_CARD_DETAILS: `${MY_PROFILE}/edit-card-details`,
};

export const UPGRADE_PAGES = {
  SELECT_YOUR_PLAN: `${PAGES.UPGRADE}/select-your-plan`,
};

export const CHECKOUT_URL = {
  SUCCESS: `${BUSINESS_SITE_URL}${PAGES.MANAGE_USERS}`,
  CANCEL: `${BUSINESS_SITE_URL}${PAGES.MANAGE_USERS}`,
};
