import { INonEditableFields, ITeamCustomMetadata } from "./Team";

export type ProfileStates =
  | "is_representative"
  | "is_appointed"
  | "is_normal"
  | "is_business"
  | "is_user_editable";

export interface IProfileResponse {
  profiles: IProfile[];
}

export enum PROFILE_WIZARD_STEP {
  COMPLETE = "complete",
}

export interface IProfile {
  team_custom_metadata: ITeamCustomMetadata;
  is_team_profile: boolean;
  is_team_admin: boolean;
  id: number;
  team_id: number;
  handle: string;
  first_name: string;
  last_name: string;
  state: ProfileStates | null;
  picture: string | null;
  background: string | null;
  logo: string | null;
  cover_title: string;
  job_title: string;
  bio: string;
  videos: IVideo[];
  thumbnail_links: [];
  is_business: boolean;
  user: null;
  view_stats: IViewStats;
  sections: ISection[];
  injected: IInjected;
  profile_hits: number;
  profile_exchanges: number;
  profile_saves: number;
  cards: [];
  appointed_email: string | null;
  work_category: number;
  vcard_hint: null;
  non_editable_fields: INonEditableFields;
  is_default: boolean;
  eff_email: string | null;
  public_id: string;
  designation: string;
  design: IDesign;
  workplace: string;
  is_user_editable: boolean;
  wizard_step: PROFILE_WIZARD_STEP;
  admin_wizard_step: PROFILE_WIZARD_STEP;
  company_size: string | null;
  show_company_name: boolean;
  company_name_separator: string;
}

export interface IDesign {
  id: number;
  title: string;
  raw_file: null;
  full_name_foreground_color: string;
  career_foreground_color: string;
  card_background_color: string;
  background_color: string;
  accent_color: string;
  foreground_active_color: string;
  brand_color: string;
  team: number;
  user: null;
  order: null;
}

interface IInjected {
  videos: [];
  thumbnail_links: [];
  sections: [];
}

interface ISection {
  id: string;
  title: string;
  icon: null;
  presentation: {};
  fields: [];
}

interface IViewStats {
  start_date_inclusive: string;
  end_date_exclusive: string;
  unique_count: number;
  aggregated_count: number;
}

interface IVideo {
  id: number;
  updated: string;
  title: string;
  link: string;
  order: number;
}
