import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";

interface UpgradeModalContextType {
  showUpgradeModal: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const UpgradeModalContext = createContext<UpgradeModalContextType | undefined>(
  undefined,
);

export const UpgradeModalProvider = ({ children }: { children: ReactNode }) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const openModal = useCallback(() => {
    setShowUpgradeModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowUpgradeModal(false);
  }, []);

  return (
    <UpgradeModalContext.Provider
      value={{ showUpgradeModal, openModal, closeModal }}
    >
      {children}
    </UpgradeModalContext.Provider>
  );
};

export const useUpgradeModal = () => {
  const context = useContext(UpgradeModalContext);
  if (context === undefined) {
    throw new Error(
      "useUpgradeModal must be used within a UpgradeModalProvider",
    );
  }
  return context;
};
