import api from "api";
import { useMutation } from "react-query";
import { TEAM_WIZARD_STEP } from "types/Team";

const completeWizardStepService = async ({ teamId }: { teamId: number }) => {
  const { data } = await api.patch(`team/${teamId}/update/`, {
    wizard_step: TEAM_WIZARD_STEP.COMPLETE,
  });
  return data;
};

export const useCompleteWizardStep = () => {
  const [mutate, { ...mutationProps }] = useMutation(
    completeWizardStepService,
    {},
  );
  return {
    mutate,
    ...mutationProps,
  };
};
