import { FEATURES } from "constants/features";
import useTracking from "./useTracking";

const useIsActionAllowed = () => {
  const track = useTracking();
  const handleAction = (
    isAllowed: boolean,
    source?: FEATURES,
    callBack?: () => unknown,
    onDisallow?: () => unknown,
  ) => {
    if (isAllowed) {
      callBack?.();
    } else {
      onDisallow?.();
      track({ upgrade: true, customTrackingProps: { source } });
    }
  };

  return { handleAction };
};

export default useIsActionAllowed;
