export interface ITeamResponse {
  teams: ITeam[];
}

export interface ITeam {
  id: number;
  custom_metadata: ITeamCustomMetadata;
  allow_self_assign: boolean;
  num_seats: number;
  pending_seats: number;
  assigned_seats: number;
  standard_design: string | null;
  title: string;
  under_subscription: boolean;
  user_name: null;
  non_editable_profile_fields: INonEditableFields;
  wizard_step: TEAM_WIZARD_STEP;
}

export interface ITeamCustomMetadata {
  sections?: Sections;
  footer_text?: string;
}

export interface INonEditableFields {
  picture: boolean;
  job_title: boolean;
  last_name: boolean;
  first_name: boolean;
}

interface Sections {
  about?: {
    enabled: boolean;
  };
  social_media?: {
    enabled: boolean;
    allowed_fields: string[];
  };
  links?: {
    enabled: boolean;
  };
  video?: {
    enabled: boolean;
  };
  contact_information?: {
    enabled: boolean;
  };
  photo_gallery?: {
    enabled: boolean;
  };
}

export enum TEAM_WIZARD_STEP {
  BUSINESS_DETAILS = "business_details",
  BUSINESS_DESIGN = "business_design",
  ADMIN_PROFILE = "admin_profile",
  ASSIGN_SEATS = "assign_seats",
  COMPLETE = "complete",
}

export type ComponentSteps = Exclude<
  TEAM_WIZARD_STEP,
  TEAM_WIZARD_STEP.COMPLETE
>;
