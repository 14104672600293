import { Box, Heading } from "@chakra-ui/react";
import { default as NewModal } from "../../components/NewModal";

const OnboardingModalTitle = () => {
  return (
    <Heading fontSize="24px" fontWeight="800" color="black" lineHeight="30px">
      Get Started in 2 Minutes!
    </Heading>
  );
};

const OnboardingModalBody = () => {
  return (
    <Box position="relative" width="100%" borderRadius="lg" overflow="hidden">
      <video width="100%" controls style={{ borderRadius: "8px" }}>
        <source
          src="https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export const OnboardingModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title={<OnboardingModalTitle />}
      subtitle="Learn how to manage your team and master the admin panel effortlessly."
      modalBody={<OnboardingModalBody />}
      modalContentStyle={{
        minWidth: "733px",
        maxWidth: "523px",
        px: "32px",
      }}
      bodyStyle={{
        px: "0px",
        pb: "32px",
      }}
      modalHeaderStyle={{
        px: "0px",
        pb: "8px",
      }}
    />
  );
};
