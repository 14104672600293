import { FC, ReactElement, useEffect, useState } from "react";
import { useMediaLayout } from "use-media";

import { Sidebar } from "features";
import { Box, Flex, Icon } from "../components";
import { AccessTokenGuard } from "./AccessTokenGuard";
import UpgradeModal from "../components/NewModal/UpgradeModal";
import { useUpgradeModal, UpgradeModalProvider } from "hooks/useUpgradeModal";

const LayoutContent: FC<{ children: ReactElement }> = ({ children }) => {
  const isMobile = useMediaLayout({ maxWidth: "768px" });
  const [isOpen, setIsOpen] = useState(() => !isMobile);
  useEffect(() => setIsOpen(!isMobile), [isMobile]);
  const { showUpgradeModal, closeModal } = useUpgradeModal();

  return (
    <div>
      <AccessTokenGuard>
        <Flex height={["", "100vh"]} overflow="hidden" backgroundColor="white">
          <Sidebar
            isMobile={isMobile}
            isOpen={isOpen}
            close={() => (isMobile ? setIsOpen(false) : null)}
          />
          <Flex
            flexDirection="column"
            w={0}
            flex="1 1 0%"
            overflow="hidden"
            className={`transition-all duration-150 ease-in-out ${
              isOpen || isMobile ? "" : "-ml-72"
            }`}
          >
            <Box
              flex="1 1 0%"
              position="relative"
              overflowY="auto"
              px={4}
              py={[10]}
              _focus={{
                outline: "2px solid transparent",
                outlineOffset: "2px",
              }}
            >
              <Flex flexDirection="column">
                {!isOpen && (
                  <Box cursor="pointer">
                    <Icon
                      name="menu"
                      className="mr-0 sm:mr-2"
                      onClick={() => setIsOpen(true)}
                    />
                  </Box>
                )}
                <Box width="100%" h="100%" mx="auto" px={[3, 10]}>
                  {children}
                </Box>
              </Flex>
            </Box>
          </Flex>

          <UpgradeModal
            showUpgradeModal={showUpgradeModal}
            onClose={closeModal}
          />
        </Flex>
      </AccessTokenGuard>
    </div>
  );
};

const NewLayout: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <UpgradeModalProvider>
      <LayoutContent>{children}</LayoutContent>
    </UpgradeModalProvider>
  );
};

export default NewLayout;
